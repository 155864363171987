import React, { useEffect, useState, useRef } from 'react';

import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link, useLocation } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
import CountUp, { useCountUp } from 'react-countup';

const Home = () => {
  useCountUp({
    ref: 'counter',
    end: 1234567,
    enableScrollSpy: true,
    scrollSpyDelay: 2000,
  });

  useEffect(function () {
    Aos.init({ disable: 'mobile' });
  }, []);

  const location = useLocation();

  const videoRef = useRef<HTMLVideoElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    const playPauseButton = buttonRef.current;

    if (!videoElement || !playPauseButton) return;

    const handlePlayPause = () => {
      playPauseButton.classList.toggle('playing');
      if (playPauseButton.classList.contains('playing')) {
        videoElement.play();
        document.body.classList.add('no-play');
        
      } else {
        videoElement.pause();
      }
    };

    const handleVideoEnded = () => {
      playPauseButton.classList.remove('playing');
    };

    playPauseButton.addEventListener('click', handlePlayPause);
    videoElement.addEventListener('ended', handleVideoEnded);

    return () => {
      playPauseButton.removeEventListener('click', handlePlayPause);
      videoElement.removeEventListener('ended', handleVideoEnded);
    };
  }, []);



interface TimeLeft {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}


const calculateTimeLeft = (): TimeLeft => {
  const now = new Date();
  

  const targetDate = new Date(now);
  targetDate.setDate(now.getDate() );
  targetDate.setHours(14); 
  targetDate.setMinutes(0);
  targetDate.setSeconds(0);
  targetDate.setMilliseconds(0);

  const cetOffset = targetDate.getTimezoneOffset() / 60 + 2; 
  targetDate.setHours(targetDate.getHours() - cetOffset);
  
  const countDownDate = targetDate.getTime();
  const nowTime = now.getTime();
  const distance = countDownDate - nowTime;

  if (distance < 0) {
    return {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    };
  }

  const days = Math.floor(distance / (1000 * 60 * 60 * 24)).toString().padStart(2, '0');
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0');
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
  const seconds = Math.floor((distance % (1000 * 60)) / 1000).toString().padStart(2, '0');

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const renderDigits = (unit: string) => {
    return unit.split('').map((digit, index) => (
      <span key={index} className="digit">
        {digit}
      </span>
    ));
  };
  

  // useEffect(() => {
  // window.scrollTo(0, 0);
  // }, [location]);

  return (
    <>
      {/* Banner */}
      <div className="bg-grad">
        <section className="banner" id="home">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 mb-md-0 mb-4 text-center" data-aos="fade-up" data-aos-duration="1100">
                <h4 className='text-center text-uppercase'>countdown</h4>
              <div className="countdown-timer">
                    <div>
                    <div className="time-unit">
                      {renderDigits(timeLeft.days)}
                    </div>
                      <div className="label">Days</div>
                    </div>
                    <div>
                    <div className="time-unit">
                      {renderDigits(timeLeft.hours)}
                    </div>
                      <div className="label">Hrs</div>
                    </div>
                    <div>
                    <div className="time-unit">
                      {renderDigits(timeLeft.minutes)}
                    </div>
                      <div className="label">Min</div>
                    </div>
                    <div>
                    <div className="time-unit">
                      {renderDigits(timeLeft.seconds)}
                    </div>
                      <div className="label">Sec</div>
                    </div>
                  </div>
                <h1 className="mb-0" >
                <span className='danger-text'>Announcing NeptuneX V2 - <br /> </span> A Smart Routing Algorithm for Optimal Order Flow
                </h1>
                <p className="my-3 py-md-2">
                A Groundbreaking Development in the DeFi 2.0 Ecosystem
                </p>

                <div className="blast"><img src="assets/blast.png" alt="" /></div>
                <div
                  className="d-flex flex-md-row flex-column  justify-content-center gap-3 mt-md-5 mt-4"
                  data-aos="fade-up"
                  data-aos-duration="2500"
                >
                  <a
                    href="https://www.mexc.com/exchange/NPTX_USDT"
                    target={'_blank'}
                    className="prime-btn"
                    rel="noopener noreferrer"
                  >
                   <img src="assets/mex.png" alt="" />
                  </a>
                  <a
                    href="https://app.thruster.finance/?token2=0x75483179a38d21F3608E71bbEdE5EC1314F0067D"
                    target={'_blank'}
                    className="prime-btn"
                    rel="noopener noreferrer"
                  >
                   <img src="assets/thru.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 mt-md-5" id="feature">
          <div className="container pt-lg-5">
            <div className="bg-3" data-aos="fade-up" data-aos-duration="1500">
              <div className="inner-bg">
                <div className="row align-items-end">
                  <div className="col-lg-11 col-md-12">
                    <div className="d-flex align-items-start gap-3 mt-md-2 mb-4 py-lg-3">
                      <img src="assets/ic-1.svg" alt="" />
                    <h3 className='mb-0'>Tap into a <span className="danger-text">$100M+ daily trading</span> market on a single <br className='d-md-block d-none' /> platform</h3>
                    </div>
                    <div className="d-flex align-items-start gap-3 mb-4 pb-lg-2">
                    <img src="assets/ic-2.svg" alt="" />
                   <h3 className='mb-0'>Access <span className="danger-text">10+ liquidity sources</span> for best prices</h3>
                    </div>

                    <div className="d-flex align-items-start gap-3 mb-4 pb-lg-2">
                    <img src="assets/ic-3.svg" alt="" />
                   <h3 className='mb-0'><span className="danger-text">Lightning-fast</span> Rust-based trading backbone</h3>
                    </div>
                    <div className="d-flex align-items-start gap-3 mb-4 pb-lg-2">
                    <img src="assets/ic-4.svg" alt="" />
                   <h3 className='mb-0'><span className="danger-text">Advanced</span> smart order routing <span className="danger-text">algorithm</span></h3>
                    </div>
                    <div className="d-flex align-items-start gap-3 mb-md-4 pb-lg-2">
                    <img src="assets/ic-5.svg" alt="" />
                   <h3 className='mb-0'><span className="danger-text">Zero trading fees</span> on all swaps and pairs</h3>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 mt-md-5" id="feature">
          <div className="container pt-lg-5">
            <div className="row">
              <div className="col-md-12" data-aos="zoom-in" data-aos-duration="1100">
                  <div className="video-container">
                  <video ref={videoRef} controls src="assets/video.mp4" className='w-100' poster='assets/video.png'></video>

                  <div className="vid-btn">
                  <button className="video-control" ref={buttonRef}>
                  <span className="video-control-play">
                    <span className="video-control-symbol" aria-hidden="true"><img src="assets/play.svg" alt="" /></span>
                  </span>
                </button>
                  </div>
                  {/* <button  className='vid-btn'><img src="assets/play.svg" alt="" /></button> */}
                  </div>
                {/* <a href="javascript:void(0)"><img src="assets/video.png" className='w-100' alt="" /></a> */}
              </div>
            </div>
          </div>
        </section>

        <section className="powered pt-5 mt-lg-5 d-md-block d-none">
          <div className="container">
            <div
              className="row align-items-center"
              data-aos="fade-up"
              data-aos-duration="2700"
            >
              <div className="col-md-12">
                <h3 className="mb-4">Featured in</h3>
              </div>
              <div className="col-md-2 col-6 px-lg-4 mb-md-0 mb-3"><img src="assets/fin.png" className='w-100 h-auto' alt="" /></div>
              <div className="col-md-2 col-6 px-lg-4 mb-md-0 mb-3"><img src="assets/ai.png" className='w-100 h-auto' alt="" /></div>
              <div className="col-md-2 col-6 px-lg-4 mb-md-0 mb-3"><img src="assets/crypto.png" className='w-100 h-auto' alt="" /></div>
              <div className="col-md-2 col-6 px-lg-4 mb-md-0 mb-3"><img src="assets/gn.png" className='w-100 h-auto' alt="" /></div>
              <div className="col-md-2 col-6 px-lg-4 mb-md-0"><img src="assets/ben.png" className='w-100 h-auto' alt="" /></div>
              <div className="col-md-2 col-6 px-lg-4 mb-md-0"><img src="assets/mw.png" className='w-100 h-auto' alt="" /></div>
            </div>
          </div>
        </section>

        <section className="powered pt-5 mt-lg-5 d-block d-md-none">
          <div className="container">
            <div
              className="row align-items-center"
              data-aos="fade-up"
              data-aos-duration="2700"
            >
              <div className="col-md-12">
                <h3 className="mb-4">Featured in</h3>
              </div>
              <Marquee>
              <div className='mx-2'><img src="assets/fin.png"style={{height: "30px"}} alt="" /></div>
              <div className='mx-2'><img src="assets/ai.png"style={{height: "30px"}} alt="" /></div>
              <div className='mx-2'><img src="assets/crypto.png"style={{height: "80px"}} alt="" /></div>
              <div className='mx-2'><img src="assets/gn.png"style={{height: "45px"}} alt="" /></div>
              <div className='mx-2'><img src="assets/ben.png"style={{height: "40px"}} alt="" /></div>
              <div className='mx-2'><img src="assets/mw.png"style={{height: "50px"}} alt="" /></div>
              </Marquee>
            </div>
          </div>
        </section>

        <section className="bg-2">
          <div className="container">
            <div className="row" data-aos="fade-right" data-aos-duration="1000">
              <div className="col-lg-10 col-md-11 mb-lg-4">
                <h2 className="mb-lg-5 mb-4">
                  A{' '}
                  <span className="danger-text"> Smart Routing Algorithm </span>
                  for Optimal Order Flow
                </h2>
              </div>
            </div>
            <div className="row" data-aos="fade-up" data-aos-duration="1500">
              <div className="col-lg-3 col-md-6 mb-4">
                <h3>
                  <CountUp end={118} enableScrollSpy />M
                  <span className="danger-text">+</span>
                </h3>
                <p>Daily Trading Volume*</p>
              </div>

              <div className="col-lg-3 col-md-6 mb-4">
                <h3>
                  <CountUp end={40} enableScrollSpy />k
                  <span className="danger-text">+</span>
                </h3>
                <p>Daily Trades*</p>
              </div>

              <div className="col-lg-3 col-md-6 mb-4">
                <h3>
                  <CountUp end={200} enableScrollSpy />
                  <span className="danger-text">+</span>
                </h3>
                <p>Trading Pairs*</p>
              </div>

              <div className="col-lg-3 col-md-6 mb-4 nine">
                <h3>
                  <CountUp end={9} enableScrollSpy />
                </h3>
                <p>DEXs*</p>
              </div>
            </div>
            <p
              className="danger-text text-md-end fs-18"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              *Total Blast Market Size
            </p>
          </div>
        </section>
        <section className="powered pb-5">
          <div className="container">
            <div
              className="row align-items-center"
              data-aos="fade-up"
              data-aos-duration="2700"
            >
              <div className="col-md-12">
                <h3 className="mb-md-5 mb-4">Powered by</h3>
              </div>
              <Marquee>
                <div className="px-lg-3"><img src="assets/pw1.svg" className="mx-4" alt="" /></div>
                <div className="px-lg-3"><img src="assets/pw2.svg" className="mx-4" alt="" /></div>
                <div className="px-lg-3"><img src="assets/pw3.svg" className="mx-4" alt="" /></div>
                <div className="px-lg-3"><img src="assets/pw4.svg" className="mx-4" alt="" /></div>
                <div className="px-lg-3"><img src="assets/pw5.svg" className="mx-4" alt="" /></div>
              </Marquee>
            </div>
          </div>
        </section>

        

       

        <section className="py-lg-5" id="campaign">
          <div className="container pt-5">
            <div className="row justify-content-center pb-4 text-md-start text-center">
              <div
                className="col-lg-9 mb-4"
                data-aos="fade-up"
                data-aos-duration="1800"
              >
                <h2 className="mb-md-4 mb-0">
                  <span className="danger-text">Join The Trading Revolution</span>
                </h2>
              </div>
              <div className="col-lg-3">
                <h4 className="mb-md-4 mb-2 fw-600">Token Contract</h4>
              <p className='fs-20'>
                <a href="https://blastscan.io/token/0x75483179a38d21F3608E71bbEdE5EC1314F0067D" target={'_blank'}>
              NPTX Contract (ERC-20)</a>
                </p>
              </div>
            </div>
          </div>
        </section>

       
      </div>
    </>
  );
};

export default Home;
