import React from 'react';

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container py-3">
          <div className="row border-bottom border-dark">
            
          </div>
          <div className="row align-items-center pt-4">
            <div className="col-md-6 mb-md-0 mb-4">
              <div className="d-flex flex-md-row flex-column align-items-md-center gap-4">
                <span>
                  <img src="assets/logo.png" className="light-logo" alt="" />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-md-row flex-column justify-content-md-end justify-content-start align-items-md-center gap-4">
                <div className="d-flex align-items-center gap-4">
                  <a
                    href="https://github.com/NeptuneX-Aggregator"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-ic"
                  >
                    <span>
                      <img src="assets/git.svg" alt="" />
                    </span>
                  </a>
                  <a
                    href="https://medium.com/@NeptuneX_io"
                    target={'_blank'}
                    rel="noopener noreferrer"
                    className="footer-ic"
                  >
                    <span>
                      <img src="assets/medium.svg" alt="" />
                    </span>
                  </a>
                  <a
                    href="https://twitter.com/NeptuneX_io_"
                    target={'_blank'}
                    rel="noopener noreferrer"
                    className="footer-ic"
                  >
                    <span>
                      <img src="assets/x.svg" alt="" />
                    </span>
                  </a>
                  <a
                    href="https://discord.gg/NeptuneX"
                    target={'_blank'}
                    rel="noopener noreferrer"
                    className="footer-ic"
                  >
                    <span>
                      <img src="assets/discord.svg" alt="" />
                    </span>
                  </a>
                </div>
                <div>
                  <a
                    href="http://docs.NeptuneX.io"
                    target={'_blank'}
                    rel="noopener noreferrer"
                    className="primary-btn px-5"
                  >
                    Docs
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-12 pt-4">
              <div className="d-flex flex-wrap align-items-start  align-items-md-center sub-footer">
                <p className="mb-0">Copyright 2024 NeptuneX</p>
                <p className="mb-0">All rights reserved</p>
                <a
                  href="http://docs.NeptuneX.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  Terms of Service{' '}
                </a>
                <a
                  href="http://docs.NeptuneX.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  Privacy Policy{' '}
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
